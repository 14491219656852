import React from 'react';
import { bool } from 'prop-types';
import { Layout } from '@nutkit/layouts';
import Section from '@nutkit/component-section';
import { IconTick, IconDot, iconColors } from '@nutkit/component-icon';
import Align, { justifyContentRules, alignItemsRules } from '@nutkit/component-align';
import Link from '@nutkit/component-link';
import { Heading, headingLevels, Text, textColors, textAligns, headingStyles } from '@nutkit/component-text';
import Panel from '@nutkit/component-panel';
import { SupportArticles } from '@nm-utils-lib-web/routes';
import { Trans, useTranslation } from '@nm-utils-lib-web/translations';
import { isJohnLewisFinanceGBR } from '@nm-utils-lib-web/organisations';

import RequiredItemList from '../../../RequiredItemList';

import styles from './RegisterBanner.module.scss';

const TRANSLATION_NAMESPACE = 'signup.registerPage.register';
const listItems = [
  { text: 'listItem1' },
  { text: 'listItem2' },
  { text: 'listItem3' },
  { text: 'listItem4' },
  { text: 'listItem5' },
  { text: 'listItem6' }
];

const getBannerStyles = (isMobile, isJohnLewisFinance) => {
  if (isJohnLewisFinance) {
    return {
      textColor: textColors.BLACK,
      textAlign: isMobile ? textAligns.LEFT : textAligns.CENTER,
      bulletColor: iconColors.BLACK,
      icon: IconTick
    };
  }

  return {
    textColor: isMobile ? textColors.BLACK : textColors.WHITE,
    textAlign: textAligns.LEFT,
    bulletColor: iconColors.SUCCESS,
    headingStyle: headingStyles.HIGHLIGHT,
    icon: IconDot
  };
};

const RegisterBanner = ({ isMobile }) => {
  const { t } = useTranslation();
  const isJohnLewisFinance = isJohnLewisFinanceGBR();
  const bannerStyles = getBannerStyles(isMobile, isJohnLewisFinance);

  return (
    <>
      {isMobile ? (
        <Panel>
          <RequiredItemList translationNamespace={TRANSLATION_NAMESPACE} listItems={listItems} {...bannerStyles} />
        </Panel>
      ) : (
        <Section
          data-qa="register-banner-desktop"
          className={isJohnLewisFinance ? styles.jlfContainer : styles.container}
        >
          <Align alignItems={alignItemsRules.CENTER} justifyContent={justifyContentRules.CENTER} fullHeight>
            <Layout>
              <Heading
                level={headingLevels.TWO}
                headingStyle={bannerStyles.headingStyle}
                textColor={bannerStyles.textColor}
                textAlign={bannerStyles.textAlign}
              >
                {t(`${TRANSLATION_NAMESPACE}.heading`)}
              </Heading>
              <Text textColor={bannerStyles.textColor} textAlign={bannerStyles.textAlign}>
                {t(`${TRANSLATION_NAMESPACE}.text`)}
              </Text>
              <RequiredItemList translationNamespace={TRANSLATION_NAMESPACE} listItems={listItems} {...bannerStyles} />
              <Text textColor={bannerStyles.textColor} textAlign={bannerStyles.textAlign}>
                <Trans
                  i18nKey={`${TRANSLATION_NAMESPACE}.supportNote`}
                  components={[<Link noColor isExternal href={SupportArticles.CONTACT_FAQ_URL} />]}
                />
              </Text>
            </Layout>
          </Align>
        </Section>
      )}
    </>
  );
};

RegisterBanner.propTypes = {
  isMobile: bool.isRequired
};

export default RegisterBanner;
