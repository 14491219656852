import React, { FC, ReactNode } from 'react';

import { List, ListItem } from '@nutkit/component-list';
import {
  Text,
  TextColors,
  TextAligns
} from '@nutkit/component-text';
import { useTranslation } from '@nm-utils-lib-web/translations';

type RequiredItemListProps = {
  textColor: TextColors;
  textAlign: TextAligns;
  icon: FC;
  bulletColor: string;
  listItems: {
    text: string;
    icon?: FC;
  }[];
  translationNamespace: string;
}

const RequiredItemList: FC<RequiredItemListProps> = ({ textColor, textAlign, icon, bulletColor, translationNamespace, listItems }) => {
  const { t } = useTranslation();

  return (
    <>
      <Text textWeight="bold" textColor={textColor} textAlign={textAlign}>
        {t(`${translationNamespace}.listTitle`)}
      </Text>
      <List icon={icon} bulletColor={bulletColor} space="sm">
        {listItems.map(item => (
          <ListItem key={item.text} icon={item.icon}>
            <Text tagName="span" textColor={textColor}>
              {t(`${translationNamespace}.${item.text}`)}
            </Text>
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default RequiredItemList;
