import React from 'react';
import Section from '@nutkit/component-section';
import Panel from '@nutkit/component-panel';
import { Container } from '@nutkit/component-grid';
import { useTranslation } from '@nm-utils-lib-web/translations';
import { Heading, Text } from '@nutkit/component-text';
import Align from '@nutkit/component-align';
import { useBreakpoint, breakpoints, breakpointDirections } from '@nutkit/react-utils';

import styles from './RegisterEmailBanner.module.scss';

import RegisterEmailForm from '../RegisterEmailForm';
import RegisterEmailAboutNutmeg from '../RegisterEmailAboutNutmeg';

const TRANSLATION_NAMESPACE = 'signup.registerEmail.banner';

const RegisterEmailBanner = () => {
  const { t } = useTranslation();
  const { matchesCondition: isLessThanLargeBreakpoint } = useBreakpoint(breakpoints.LG, breakpointDirections.DOWN);
  return (
    <Section className={styles.container}>
      <Container className={styles['banner-container']}>
        <Align alignItems="center" justifyContent="center" fullHeight>
          <Panel space='inset--lg'>
            <Heading>{t(`${TRANSLATION_NAMESPACE}.heading`)}</Heading>
            <hr className={styles.divider}/>
            {
              isLessThanLargeBreakpoint ?
                <RegisterEmailForm />
                :
                <RegisterEmailAboutNutmeg />
            }
          </Panel>
        </Align>
      </Container>
    </Section>
  );
};

export default RegisterEmailBanner;