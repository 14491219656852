import { Text } from '@nutkit/component-text';
import { useTranslation } from '@nm-utils-lib-web/translations';
import { Logo, OrganisationLogo } from '@nutkit/component-icon';

import styles from './RegisterEmailAboutNutmeg.module.scss';

const TRANSLATION_NAMESPACE = 'signup.registerEmail.register.aboutNutmeg';

const RegisterEmailAboutNutmeg = () => {
  const { t } = useTranslation();

  return (
    <>
      <Text>{t(`${TRANSLATION_NAMESPACE}.content`)}</Text>
      <Text>{t(`${TRANSLATION_NAMESPACE}.disclaimer`)}</Text>
      <footer className={styles.footer}>
        <Logo
          component={OrganisationLogo}
        />
        <hr className={styles.divider}/>
        <Logo
          extended
          component={OrganisationLogo}
        />
      </footer>
    </>
  );
};

export default RegisterEmailAboutNutmeg;