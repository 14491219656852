import React from 'react';
import { IconDot, IconBar, IconBlock, IconCard } from '@nutkit/component-icon';
import Section from '@nutkit/component-section';
import { Container } from '@nutkit/component-grid';
import Align from '@nutkit/component-align';
import { useTranslation } from '@nm-utils-lib-web/translations';
import { useBreakpoint, breakpoints, breakpointDirections } from '@nutkit/react-utils';

import SupportPanel from '../SupportPanel';
import RegisterEmailForm from '../RegisterEmailForm';
import RegisterEmailAboutNutmeg from '../RegisterEmailAboutNutmeg';
import RequiredItemList from '../../RequiredItemList';

import styles from './RegisterEmail.module.scss';

const TRANSLATION_NAMESPACE = 'signup.registerEmail.register';
const listItems = [
  { text: 'listItem1', icon: IconDot },
  { text: 'listItem2', icon: IconBar },
  { text: 'listItem3', icon: IconBlock },
  { text: 'listItem4', icon: IconCard },
];

const RegisterEmail = () => {
  const { t } = useTranslation();
  const { matchesCondition: isLessThanLargeBreakpoint } = useBreakpoint(breakpoints.LG, breakpointDirections.DOWN);

  return (
    <Align alignItems="center" justifyContent="center" direction="column" fullHeight>
      <Container className={styles.container}>
        <SupportPanel />
        <Section className={styles['required-item-list-container']}>
          <RequiredItemList
            textColor="black"
            textAlign="left"
            bulletColor="black"
            icon={IconDot}
            translationNamespace={TRANSLATION_NAMESPACE}
            listItems={listItems}
          />
        </Section>
        {!isLessThanLargeBreakpoint ? <RegisterEmailForm /> : <RegisterEmailAboutNutmeg />}
      </Container>
    </Align>
  );
};

export default RegisterEmail;
