import React from 'react';
import Panel from '@nutkit/component-panel';
import { Heading, Text } from '@nutkit/component-text';
import { Trans, useTranslation } from '@nm-utils-lib-web/translations';
import Bold from '@nutkit/component-bold';
import Section from '@nutkit/component-section';
import { IconPhone, IconWhatsApp } from '@nutkit/component-icon';
import Icon from '@nutkit/component-icon';
import { useBreakpoint, breakpoints, breakpointDirections } from '@nutkit/react-utils';

import styles from './SupportPanel.module.scss';

const TRANSLATION_NAMESPACE = 'signup.registerEmail.register.support';

const SupportPanel = () => {
  const { t } = useTranslation();
  const { matchesCondition: isLessThanSmallBreakpoint } = useBreakpoint(breakpoints.SM, breakpointDirections.DOWN);

  return (
    <Panel className={styles['support-panel']}>
      <Heading level="2">
        {t(`${TRANSLATION_NAMESPACE}.title`)}
      </Heading>
      <Text>
        <Text>
          <Trans
            i18nKey={`${TRANSLATION_NAMESPACE}.content`}
            components={[
              <Bold weight="bold">
                {' '}
              </Bold>
            ]}
          />
        </Text>
      </Text>
      <Section className={styles['support-detail-container']}>
        <Text className={styles['support-detail-item']} noStack={!isLessThanSmallBreakpoint}>
          <Icon className={styles.nmContactsIcon} component={IconPhone} color="black" />
          {t(`${TRANSLATION_NAMESPACE}.phoneNumber`)}
        </Text>
        <Text className={styles['support-detail-item']} noStack={!isLessThanSmallBreakpoint}>
          <Icon className={styles.nmContactsIcon} component={IconWhatsApp} color="black"/>
          {t(`${TRANSLATION_NAMESPACE}.email`)}
        </Text>
      </Section>
    </Panel>
  )
};

export default SupportPanel;
