import React, { useRef, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import Button from '@nutkit/component-button';
import ButtonGroup from '@nutkit/component-button-group';
import Link from '@nutkit/component-link';
import { InputField, CheckboxInputField } from '@nm-ui-shared-lib-web/form';
import { Trans, useTranslation } from '@nm-utils-lib-web/translations';
import { Marketing } from '@nm-utils-lib-web/routes';
import { Text, Heading } from '@nutkit/component-text';
import { Modal } from '@nutkit/component-modal';

import { registerEmailFormValidationSchema } from './registerEmailFormValidationSchema';

const TRANSLATION_NAMESPACE = 'signup.registerEmail.form';

const RegisterEmailForm = () => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const modalToogle = () => {
    setIsModalOpen(isModalOpen => !isModalOpen);

    inputRef?.current?.focus();
  };

  return (
    <Formik
      initialValues={{
        email: '',
        consent: false,
      }}
      validateOnMount
      onSubmit={async values => {
        //TODO - call endpoint to save email
      }}
      enableReinitialize
      validateOnChange
      validationSchema={registerEmailFormValidationSchema}
    >
      {({ errors, values, isValid, handleBlur, submitForm }) => {
        const onGetStartedClick = () => {
          submitForm();
        };

        return (
          <Form data-qa="register-form">
            <Field
              id="email"
              type="email"
              name="email"
              onBlur={(e: FocusEvent) => {
                handleBlur(e);
              }}
              aria-label={errors.email}
              label={t(`${TRANSLATION_NAMESPACE}.fields.email.label`)}
              translationNamespace={TRANSLATION_NAMESPACE}
              data-qa="register-email-form-input"
              component={InputField}
              innerRef={inputRef}
            />
            <Field
              id="consent"
              name="consent"
              label={
                <Text>
                  <Trans
                    i18nKey={`${TRANSLATION_NAMESPACE}.fields.consent.label`}
                    components={[
                      <Link href={Marketing.SITE_TERMS_URL} isExternal>
                        {' '}
                      </Link>,
                      <Link href={Marketing.PRIVACY_POLICY_URL} isExternal>
                        {' '}
                      </Link>,
                    ]}
                  />
                </Text>
              }
              translationNamespace={TRANSLATION_NAMESPACE}
              data-qa="register-email-form-consent"
              component={CheckboxInputField}
              isConsent
            />
            <ButtonGroup stackUp stackOnMobile stack align="right">
              <Button type="submit" data-qa="register-form-submit-button" onClick={modalToogle} disabled={!isValid}>
                {t(`${TRANSLATION_NAMESPACE}.getStartedButton`)}
              </Button>
            </ButtonGroup>
            <Modal isOpen={isModalOpen} toggle={modalToogle}>
              <Heading level="2">{t(`${TRANSLATION_NAMESPACE}.modal.title`)}</Heading>
              <Text>{t(`${TRANSLATION_NAMESPACE}.modal.description`)}</Text>
              <Text noStack>{t(`${TRANSLATION_NAMESPACE}.modal.confirmation`)}</Text>
              <Text textWeight="bold">{values.email}</Text>
              <ButtonGroup stackUp>
                <Button size="sm" cta="secondary" onClick={modalToogle}>
                  {t(`${TRANSLATION_NAMESPACE}.modal.editButton`)}
                </Button>

                <Button size="sm" onClick={onGetStartedClick}>
                  {t(`${TRANSLATION_NAMESPACE}.modal.confirmButton`)}
                </Button>
              </ButtonGroup>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RegisterEmailForm;
